// Angular import
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SubscriptionDetails, UserProfile} from './models/app.user.model';
import {LocationSelectionModel} from './models/app.location.model';
import {BreakpointObserver} from '@angular/cdk/layout';
import {BusinessService} from './services/app.business.service';
import {RoleService} from './services/app.role.service';
import {LocationRoleAndPermissionModel} from './models/app.role.model';
import {MenuModel} from './models/app.menu.model';
import {filter, map} from 'rxjs/operators';
import {AppUtils} from './shared/AppUtils';
import {BusinessFormModel} from './models/app.business.model';
import {AppoinmentDateUtils} from './layout/content-full-layout/appointment/AppoinmentDateUtils';
import {DashboardModel} from './models/app.dashboard.model';
import {DashboardService} from './services/app.dashboard.service';
import {AuthService} from './services/app.authenication.service';

export type InternalStateType = {
    [key: string]: any
};

@Injectable()
export class AppState {

    loadSelectedUserLocation: boolean = false;
    public contrastBackground: boolean = false;
    contrastBackgroundSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public parentBusinessId: string;
    public userProfile: UserProfile;
    userProfileSubject: BehaviorSubject<UserProfile> = new BehaviorSubject(null);

    public authenicatied: boolean;
    authenicatiedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _businessDetailsSubject: BehaviorSubject<BusinessFormModel> = new BehaviorSubject(null);

    public isTablet: boolean = false;
    public sizeChangedState: Subject<boolean> = new Subject();

    public selectedTab: number = 0;
    public selectedTabState: Subject<number> = new Subject();

    public selectedSpecialisationTab: number = 0;
    public selectedSpecialisationTabState: Subject<number> = new Subject();

    public selectedAppointmentTab: number = 0;
    public selectedAppointmentTabState: Subject<number> = new Subject();

    public selectedAirTab: number = 0;
    public selectedAirTabState: Subject<number> = new Subject();

    public selectedPatientTab: number = 0;
    public selectedPatientTabState: Subject<number> = new Subject();

    public selectedBillingTab: number = 0;
    public selectedBillingTabState: Subject<number> = new Subject();
    public selectedUserLocationIdState: Subject<string> = new Subject();
    public selectedUserLocationState: Subject<LocationSelectionModel> = new Subject();

    /** Dashboard Status Checklist Status For Menu */
    private _dashboardCheckListStatus: Subject<DashboardModel> = new Subject<DashboardModel>();
    private _dashboardCheckListStatusValue: DashboardModel;

    /* Role & Permissions By Nik */
    private modulesNPermissionsSubject: Subject<{ [x: string]: { [x: string]: string } }> = new Subject<{
        [x: string]: { [x: string]: string }
    }>();
    private _modulesNPermissions: { [x: string]: { [x: string]: string } } = {};
    private isFetchingPermissions: boolean = false;

    private subscriptionDetails: BehaviorSubject<SubscriptionDetails> = new BehaviorSubject<SubscriptionDetails>(null);
    subscriptionDetails$ = this.subscriptionDetails.asObservable();

    /* End - Role & Permissions By Nik */

    public get selectedUserLocation() {
        let l = localStorage.getItem('selectedUserLocation');
        if (AppUtils.getStringDefault(l, null) == 'undefined') {
            l = null;
        }
        return l ? JSON.parse(l) : null;
    }

    public set selectedUserLocation(v) {
        if (v) {
            v['userId'] = this.UserProfile ? this.UserProfile.id : null;
            AppoinmentDateUtils.setTimeZone(v.ianaTimeZone);
        }
        localStorage.setItem('selectedUserLocation', JSON.stringify(v));
    }

    public get useId() {
        return this.userProfile ? this.userProfile.id : null;
    }

    public get selectedUserLocationId() {
        return localStorage.getItem('selectedUserLocationId');
    }

    public set selectedUserLocationId(v) {
        localStorage.setItem('selectedUserLocationId', v);
    }

    _state: InternalStateType = {};

    public sideNavToggle: boolean = true;
    public sideNavState: Subject<boolean> = new Subject();

    public locationList: LocationSelectionModel[];
    public locationListState: BehaviorSubject<LocationSelectionModel[]> = new BehaviorSubject<LocationSelectionModel[]>([]);
    locationInterval: any;

    public permissions: LocationRoleAndPermissionModel[];
    public permissionState: Subject<LocationRoleAndPermissionModel[]> = new Subject();

    public toolsPermission: LocationRoleAndPermissionModel;
    public toolsPermissionState: Subject<LocationRoleAndPermissionModel> = new Subject();

    public businessAccess: string[] = [];
    private businessPermissionState: Subject<string[]> = new Subject();
    public locationAccess: string[] = [];
    private locationPermissionState: Subject<string[]> = new Subject();

    private _isLocationLoading: boolean = false;
    private _isBusinessLoadedNLoading: boolean = false;

    publicUrls: string[] = [
        '/account/login',
        '/account/loginFull',
        '/account/register',
        '/account/forgotpassword',
        '/account/activate',
        '/account/resetPassword'
    ];

    private testUserAccountNames: string[] = [
        'anushaprasanna27@gmail.com',
        'kavaniiowner@gmail.com',
        'kavaniimanager@gmail.com',
        'kavaniiacct@gmail.com',
        'kavaniirec@gmail.com',
        'kavaniipractitioner@gmail.com'
    ];

    public topMenus: MenuModel[] = [
        {moduleName: '', name: 'Dashboard', link: '/dashboard', icon: 'S_Dashboard_Icon.svg', hover: 'Dashboard', show: true},
        {moduleName: 'tools', name: 'Tools', link: '/tools', icon: 'S_Tools_Icon.svg', hover: 'Tools', show: false},
        {moduleName: 'staff', name: 'Staffs', link: '/staffs', icon: 'S_Staff_Icon.svg', hover: 'Staffs', show: false},
        {moduleName: 'offerings', name: 'Offerings', link: '/offerings', icon: 'S_Offerings_Icon.svg', hover: 'Offering', show: false},
        {moduleName: 'contacts', name: 'Contacts', link: '/contacts', icon: 'S_Contacts_Icon.svg', hover: 'Contacts', show: false},
    ];

    public menus: MenuModel[] = [
        {
            moduleName: 'appointment',
            name: 'Appointments',
            link: '/appointment',
            icon: 'S_Appointments_Icon.svg',
            hover: 'Appointments',
            show: false
        },
        {moduleName: 'patients', name: 'Patients', link: '/patients', icon: 'S_Patient_Icon.svg', hover: 'Patients', show: false},
        {moduleName: 'bills', name: 'Billing', link: '/bills', icon: 'S_Bills_Icon.svg', hover: 'Bills', show: false},
        {moduleName: 'expenses', name: 'Expenses', link: '/expenses', icon: 'S_Expenses_Icon.svg', hover: 'Expenses', show: false},
        {
            moduleName: 'communications',
            name: 'Communications',
            link: '/communications',
            icon: 'S_Communications_Icon.svg',
            hover: 'Communications (Email, SMS)',
            show: false
        },
        {moduleName: 'reports', name: 'Reports', link: '/reports', icon: 'S_Reports_Icon.svg', hover: 'Reports', show: false},
        {moduleName: 'settings', name: 'Settings', link: '/settings', icon: 'S_Settings_Icon.svg', hover: 'Settings', show: false},
    ];

    constructor(private breakpointObserver: BreakpointObserver,
                public businessService: BusinessService,
                public roleService: RoleService,
                private dashboardService: DashboardService,
    ) {

        this.populateStateChangeEvents();
        // router.events.subscribe(event => {
        //   if (event instanceof NavigationEnd) {
        //     if (this.publicUrls.indexOf(event.url) < 0) {
        //       if (this.selectedUserLocation) {
        //         this.loadUserRolePermissions(this.selectedUserLocation);
        //         this.routeAccessDenied(event.url);
        //       }
        //     }
        //   }
        // });
    }

    // routeAccessDenied(url: string) {
    //   if (this.applicableForPermission()) {
    //     if (this.permissions && this.permissions.length > 0) {
    //       let module = this.routeModuleMapper(url);
    //       let subModule = '';
    //
    //       if (module.indexOf(',') > 0) {
    //         let split = module.split(',');
    //         module = split[0];
    //         subModule = split[1];
    //       }
    //
    //       let permission_Module = this.permissions.find(p => p.moduleName == module);
    //       if (subModule) {
    //         let p = this.getSubModulePermission(module, subModule);
    //         if (!permission_Module || (!permission_Module.isFullAccess && p.length == 0)) {
    //           this.router.navigate(['/accessDenied']);
    //         }
    //       } else {
    //         if (!permission_Module || (!permission_Module.isFullAccess && permission_Module.appSubModules.length == 0)) {
    //           this.router.navigate(['/accessDenied']);
    //         }
    //       }
    //     }
    //   }
    // }

    private populateStateChangeEvents() {
        this.dashboardCheckListStatus.subscribe(data => {
            this._dashboardCheckListStatusValue = data;
            this.topMenus.forEach(menu => {
                menu.show = data.isLocationAdded;
            });
            this.menus.forEach(menu => {
                menu.show = data.isLocationAdded;
            });
            this.topMenus[0].show = true;
        });

        this.modulesNPermissionsSubject.subscribe(value => {
            this._modulesNPermissions = value ?? {};
        });

        this.authenicatiedSubject.subscribe(s => {
            setTimeout(() => {
                this.authenicatied = s;
            });
        });

        this.selectedUserLocationState
            .pipe(
                filter(c => this.selectedUserLocationId != c.id || !this.loadSelectedUserLocation)
            )
            .subscribe(c => {
                this.loadSelectedUserLocation = true;
                this.selectedUserLocation = c;
                if (c) {
                    this.selectedUserLocationIdState.next(c.id);
                    // this.selectedUserLocationId = c.id;
                }
            });

        this.selectedUserLocationIdState.pipe(
            filter(v => v !== this.selectedUserLocationId || !this.selectedUserLocationId)
        ).subscribe(c => {
            this.selectedUserLocationId = c;
            if (this.locationList) {
                this.selectedUserLocation = this.locationList.find(l => l.id == c);
            }
            this.fetchRolesAndPermission();
        });

        this.locationListState.subscribe(s => {
            this.locationList = s;
            // if (this.locationList && this.locationList.length > 0) {
            //   this.locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
            // }
            if (!this.selectedUserLocation && this.locationList && this.locationList.length > 0) {
                this.selectedUserLocationState.next(this.locationList[0]);
            } else {
                const data = this.selectedUserLocation;
                if (data && this.UserProfile && data.userId == this.UserProfile.id) {
                    this.selectedUserLocationState.next(this.selectedUserLocation);
                } else if (this.locationList && this.locationList.length > 0) {
                    this.selectedUserLocationState.next(this.locationList[0]);
                }
            }
        });

        this.userProfileSubject.subscribe(s => {
            this.userProfile = s;
            if (s) {
                this.parentBusinessId = s.parentBusinessId;
            }
            if ((!this.locationList || this.locationList.length == 0) && s) {
                this.loadUserLocations();
            }
        });

        this.contrastBackgroundSubject.subscribe(s => {
            setTimeout(() => {
                this.contrastBackground = s;
            });
        });

        this.sizeChangedState.subscribe(c => {
            this.isTablet = c;
        });

        this.breakpointObserver.observe(['(min-width: 768px) and (max-width: 1366px)']).subscribe(result => {
            this.sizeChangedState.next(result.matches);
        });

        this.selectedTabState.subscribe(c => {
            this.selectedTab = c;
        });
        this.selectedSpecialisationTabState.subscribe(c => {
            this.selectedSpecialisationTab = c;
        });
        this.selectedAppointmentTabState.subscribe(c => {
            this.selectedAppointmentTab = c;
        });
        this.selectedPatientTabState.subscribe(c => {
            this.selectedPatientTab = c;
        });
        this.selectedAirTabState.subscribe(c => {
            this.selectedAirTab = c;
        });
        this.selectedBillingTabState.subscribe(c => {
            this.selectedBillingTab = c;
        });

        this.sideNavState.subscribe(s => {
            setTimeout(() => {
                this.sideNavToggle = s;
            });
        });

        this.permissionState.subscribe(s => {
            this.permissions = s;
        });

        this.toolsPermissionState.subscribe(s => {
            this.toolsPermission = s;
        });

        this.businessPermissionState.subscribe(s => {
            this.businessAccess = s;
        });

        this.locationPermissionState.subscribe(s => {
            this.locationAccess = s;
        });
    }

    public loadLocationWhenReady() {
        if (this.userProfile) {
            this.loadUserLocations();
        } else {
            this.locationInterval = setInterval(() => {
                if (!this.userProfile) {
                    this.loadLocationWhenReady();
                    clearInterval(this.locationInterval);
                }
            }, 500);
        }
    }

    public loadUserLocations() {
        if (!this._isLocationLoading) {
            this._isLocationLoading = true;
            this.businessService.getLocationsForCurrentUser(true).subscribe(l => {
                this._isLocationLoading = false;
                this.locationListState.next(l);
                // if (!this.selectedUserLocation && this.locationList && this.locationList.length > 0) {
                //     this.selectedUserLocationState.next(this.locationList[0]);
                // } else {
                //     const data = this.selectedUserLocation;
                //     if (data && data.userId == this.UserProfile.id) {
                //         this.selectedUserLocationState.next(this.selectedUserLocation);
                //     } else if (this.locationList && this.locationList.length > 0) {
                //         this.selectedUserLocationState.next(this.locationList[0]);
                //     }
                // }
            });
        }
    }

    // private loadUserRolePermissions(location: LocationSelectionModel) {
    //   if (this.publicUrls.indexOf(this.getUrl(this.router.url)) < 0) {
    //     if (location) {
    //       // this.roleService.getRoleAndPermissionsByUserLocation(location.id).subscribe(data => {
    //       //   this.permissionState.next(data);
    //       //   this.populateMenuApperance(data);
    //       // });
    //     }
    //   }
    // }

    // private populateMenuApperance(permission: LocationRoleAndPermissionModel[]) {
    //   if (this.applicableForPermission()) {
    //     this.topMenus.forEach(tm => {
    //       let p = permission.find(p => p.moduleName == tm.name);
    //       tm.show = p != null && (p.isFullAccess || p.appSubModules.length > 0);
    //     });
    //
    //     this.menus.forEach(m => {
    //       let p = permission.find(p => p.moduleName == m.name);
    //       m.show = p != null && (p.isFullAccess || p.appSubModules.length > 0);
    //     });
    //   } else {
    //     this.topMenus.forEach(tm => {
    //       tm.show = true;
    //     });
    //     this.menus.forEach(m => {
    //       m.show = true;
    //     });
    //   }
    // }

    // getSubModulePermission(rootModuleName: string, subModuleName: string): string[] {
    //   if (this.applicableForPermission()) {
    //     let root = this.permissions.find(p => p.moduleName == rootModuleName);
    //     if (root) {
    //       let subModule = root.appSubModules.find(m => m.subModuleName == subModuleName);
    //       let subModulePermission: string[] = [];
    //       if (root.isFullAccess) {
    //         subModulePermission = ['V', 'E', 'D'];
    //       } else {
    //         subModulePermission = subModule && subModule.accessLevel ? subModule.accessLevel.split(',') : [];
    //       }
    //       return subModulePermission;
    //     }
    //   } else {
    //     return ['V', 'E', 'D'];
    //   }
    //
    //   return [];
    // }

    haveAccessToModule(moduleName: string, permission: string | string[] = 'CVED'): boolean {
        if (!moduleName) {
            return true;
        }
        const module = this.modulesNPermissions[moduleName];
        let isAny = false;
        if (module) {
            const allPermissionString = this.removeDuplicateStr(Object.keys(module).map(value => module[value]).join('')).split('');
            const finalPermission = [];
            if (Array.isArray(permission)) {
                isAny = true;
                permission = permission.join('');
            }
            for (let permissionVal of permission.split('')) {
                finalPermission.push(allPermissionString.includes(permissionVal));
            }
            return isAny ? finalPermission.some(Boolean) : finalPermission.every(Boolean);
        }
        return false;
    }

    public applicableForPermission() {
        //TODO: return true once development for role and permission is completed.
        if (this.userProfile) {
            return this.testUserAccountNames.indexOf(this.userProfile.userName) >= 0;
        }
        return false;
    }

    getCheckListStatus() {
        this.dashboardService.getDashboardCheckListView().subscribe(data => {
            this.dashboardCheckListStatus.next(data);
        });
    }


    // already return a clone of the current state
    get state() {
        return this._state = this.clone(this._state);
    }

    // never allow mutation
    set state(value) {
        throw new Error('do not mutate the `.state` directly');
    }

    get(prop?: any) {
        // use our state getter for the clone
        const state = this.state;
        //return state.hasOwnProperty(prop) ? state[prop] : state;
        return state.hasOwnProperty(prop) ? state[prop] : null;
    }

    set(prop: string, value: any) {
        // internally mutate our state
        return this._state[prop] = value;
    }

    public get IsAuthenticated(): Boolean {
        if (this.userProfile && this.userProfile.userName) {
            return true;
        }

        return false;
    }

    get UserProfile(): UserProfile {
        return this.userProfile;
    }

    setUserProfileSubject(userProfile: UserProfile) {
        // if (userProfile) {
        //   this.fetchRolesAndPermission();
        // }
        this.userProfileSubject.next(userProfile);
        if (this.userProfile && !this._isBusinessLoadedNLoading && !this.businessDetailsSubject.getValue()) {
            this._isBusinessLoadedNLoading = true;
            this.businessService.getParentBusiness(this.UserProfile.parentBusinessId).subscribe(data => {
                this._isBusinessLoadedNLoading = false;
                this.businessDetailsSubject.next(data);
            });
            this.authenicatiedSubject.next(true);
        } else {
            this.authenicatiedSubject.next(false);
        }
    }

    public clone(object: InternalStateType) {
        // simple object clone
        return JSON.parse(JSON.stringify(object));
    }

    // routeModuleMapper(route: string) {
    //   let map = '';
    //   switch (route) {
    //     case '/appointment':
    //     case '/appointment/schedule':
    //     case '/appointment/create':
    //     case '/appointment/today':
    //     case '/appointment/room':
    //     case '/appointment/waitlist':
    //       map = 'Appointment';
    //       break;
    //     case '/dashboard':
    //       map = 'Dashboard';
    //       break;
    //     case '/tools':
    //       map = 'Tools';
    //       break;
    //     case '/tools/business/edit':
    //       map = 'Tools,Business Information';
    //       break;
    //     case '/offerings':
    //       map = 'Offering';
    //       break;
    //     case '/contacts':
    //       map = 'Contacts';
    //       break;
    //   }
    //
    //   if (route.indexOf('/tools/locations') >= 0) {
    //     map = 'Tools,Business Information';
    //   }
    //
    //   if (route.indexOf('/tools/speciality') >= 0) {
    //     map = 'Tools,Specialisation';
    //   }
    //
    //   if (route.indexOf('/tools/tax') >= 0 ||
    //     route.indexOf('/tools/discountType') >= 0 ||
    //     route.indexOf('/tools/paymentType') >= 0
    //   ) {
    //     map = 'Tools,Billing';
    //   }
    //
    //   if (route.indexOf('/tools/treatmentRoom') >= 0 ||
    //     route.indexOf('/tools/appointmentType') >= 0 ||
    //     route.indexOf('/tools/missedReason') >= 0 ||
    //     route.indexOf('/tools/cancelReason') >= 0
    //   ) {
    //     map = 'Tools,Appointment settings';
    //   }
    //
    //   if (route.indexOf('/tools/marketingReferralSource') >= 0 ||
    //     route.indexOf('/tools/concession') >= 0 ||
    //     route.indexOf('/tools/documentType') >= 0
    //   ) {
    //     map = 'Tools,Patient Settings';
    //   }
    //
    //   if (route.indexOf('/settings') >= 0) {
    //     map = 'Settings';
    //   }
    //
    //   if (route.indexOf('/staffs') >= 0) {
    //     map = 'Staffs';
    //   }
    //
    //   return map;
    // }
    //
    // getRouteLinkByName(name: string) {
    //
    //   let menu = '';
    //   let subMenu = this.menus.find(m => m.name == name);
    //
    // }
    //
    // getRootPath() {
    //
    // }
    //
    // private getUrl(url: string) {
    //   return url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url;
    // }

    resetState() {
        this.setUserProfileSubject(null);
        this.locationList = null;
        // this.selectedUserLocation = null;
        this.permissions = null;
        this.menus.forEach(m => m.show = false);
        this.topMenus.forEach(m => m.show = false);
        this.modulesNPermissionsSubject.next(null);
        // localStorage.removeItem('selectedUserLocationId');
        // localStorage.removeItem('selectedUserLocation');
        localStorage.clear();
    }

    refresh() {
        this.loadUserLocations();
        this.setUserProfileSubject(this.userProfileSubject.getValue());
    }

    setSubscriptionDetails(subscriptionDetails: SubscriptionDetails) {
        this.subscriptionDetails.next(subscriptionDetails);
    }

    get businessDetailsSubject(): BehaviorSubject<BusinessFormModel> {
        return this._businessDetailsSubject;
    }

    /**
     *  Roles And Permissions By Nik
     */
    async fetchRolesAndPermission(): Promise<void> {
        const locationIdChange = !this.modulesNPermissions['location'] || this.modulesNPermissions['location']['locationId'] !== this.selectedUserLocationId;
        if (localStorage.getItem('token') && locationIdChange && !this.isFetchingPermissions || !Object.keys(this.modulesNPermissions).length) {
            this.isFetchingPermissions = true;
            const response = await this.roleService.getRolesByCurrentUser().toPromise();
            response['location'] = {locationId: localStorage.getItem('selectedUserLocationId')};
            this.modulesNPermissionsSubject.next(response);
            this.isFetchingPermissions = false;
        }
    }

    get modulesNPermissions(): { [p: string]: { [p: string]: string } } {
        return this._modulesNPermissions;
    }

    get dashboardCheckListStatus(): Subject<DashboardModel> {
        return this._dashboardCheckListStatus;
    }

    get dashboardCheckListStatusValue(): DashboardModel {
        return this._dashboardCheckListStatusValue;
    }

    removeDuplicateStr = (str) => [...new Set(str)].join('');
}
