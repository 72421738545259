import * as moment from 'moment';
import 'moment-timezone';

export class AppoinmentDateUtils {

    static timeZone = 'Australia/Sydney';
    static dateFormat: string = 'ddd, D MMM YYYY';
    static standardFormat: string = 'YYYY-MM-DD';

    static setTimeZone(tz: string) {
        this.timeZone = tz;
    }

    static getInstance(date: any, format?: string) {
        if (format) {
            return moment(date, format).tz(this.timeZone);
        }
        return moment(date).tz(this.timeZone);
    }

    static getDefaultInstance() {
        return moment().tz(this.timeZone);
    }

    public static getCurrentFormattedDate() {
        return this.getInstance(moment().tz(this.timeZone).toDate()).format(this.standardFormat);
    }

    static getCurrentDate() {
        let date = this.getDefaultInstance().format();
        return this._toDate(date);
    }

    static getCurrentDateFormat(format: string) {
        return this.getDefaultInstance().format(format);
    }

    public static getStandardFormatedDate(date) {
        return this.getInstance(date).format(this.standardFormat);
    }

    public static getFormatedDate(date) {
        return this.getInstance(date).format(this.dateFormat);
    }

    static addAndGetNewValue(date: any, num, type: string) {
        return this.getInstance(date).clone().add(type, num).format();
    }

    static getStartDateOfWeek(date: any) {
        return this.getInstance(date).startOf('isoWeek').format();
    }

    static getEndDateOfWeek(date: any, period: number) {
        return this.addAndGetNewValue(date, period, 'd');
    }

    static isBetween(start: any, end: any, date: any): boolean {
        let mm = this.getInstance(AppoinmentDateUtils.getStandardFormatedDate(date), 'YYYY-MM-DD');
        let startDate = this.format(start, 'YYYY-MM-DD');
        let endDate = this.format(end, 'YYYY-MM-DD');

        return (mm.isAfter(startDate) || mm.isSame(startDate)) && (mm.isBefore(endDate) || mm.isSame(endDate));
    }

    static isBetweenStrict(start: any, end: any, date: any): boolean {
        let mm = this.getInstance(this.getStandardFormatedDate(date), 'YYYY-MM-DD');
        let startDate = this.format(start, 'YYYY-MM-DD');
        let endDate = this.format(end, 'YYYY-MM-DD');
        let isValidStartTime = this.compareTime(date, start) >= 0;
        let isValidEndTime = this.compareTime(date, end) <= 0;

        return (mm.isAfter(startDate) || mm.isSame(startDate)) && (mm.isBefore(endDate) || mm.isSame(endDate)) && (isValidStartTime && isValidEndTime);
    }

    static isEqualStrict(date1: any, date2: any): boolean {
        let mm = this.getInstance(AppoinmentDateUtils.format(date1, 'YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
        let date2Format = this.format(date2, 'YYYY-MM-DD HH:mm');

        return mm.isSame(date2Format);
    }

    static isLessStrict(date1: any, date2: any): boolean {
        let mm = this.getInstance(AppoinmentDateUtils.format(date1, 'YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
        let date2Format = this.format(date2, 'YYYY-MM-DD HH:mm');

        return mm.isBefore(date2Format);
    }

    static isEqual(date1: any, date2: any): boolean {
        let mm = this.getInstance(AppoinmentDateUtils.getStandardFormatedDate(date1), 'YYYY-MM-DD');
        let date2Format = this.format(date2, 'YYYY-MM-DD');
        return mm.isSame(date2Format);
    }

    static isLess(date1: any, date2: any): boolean {
        let mm = this.getInstance(AppoinmentDateUtils.getStandardFormatedDate(date1), 'YYYY-MM-DD');
        let date2Format = this.format(date2, 'YYYY-MM-DD');

        return mm.isBefore(date2Format);
    }

    static isGreater(date1: any, date2: any): boolean {
        let mm = this.getInstance(AppoinmentDateUtils.getStandardFormatedDate(date1), 'YYYY-MM-DD');
        let date2Format = this.format(date2, 'YYYY-MM-DD');

        return mm.isAfter(date2Format);
    }

    static compareTime(date1: any, date2: any): number {
        const first = this.getTime(moment(date1).clone());
        const second = this.getTime(moment(date2).clone());

        // console.log(date1, date2)
        // console.log(first, second)

        if (first.isAfter(second)) {
            return 1;
        } else if (first.isSame(second)) {
            return 0;
        } else if (first.isBefore(second)) {
            return -1;
        }
    }

    static getTime(dateTime) {
        return moment({h: dateTime.hours(), m: dateTime.minutes()});
    }

    static getFormatedDateForWeek(currentDate: any, daysToAdd: number): string {
        let start = this.getFormatedDate(this.getStartDateOfWeek(currentDate));
        let end = this.getFormatedDate(this.getEndDateOfWeek(currentDate, daysToAdd));
        return start + ' to ' + end;
    }

    static format(value: any, format: string, isDefault?: boolean) {
        if (!value) {
            return '';
        }
        if (isDefault) {
            return moment(value).format(format);
        }
        return this.getInstance(value).format(format);
    }

    static formatTime(value: any, format: string, isDefault?: boolean) {
        if (isDefault) {
            return moment(value, 'HH:mm A').format(format);
        }
        return this.getInstance(value, 'HH:mm A').format(format);
    }

    static formatDateTime(value: any, format: string, isTime: boolean) {
        if (isTime) {
            return this.formatTime(value, format);
        }
        return this.format(value, format);
    }

    // FORMAT DATE/TIME WITHOUT TIMEZONE
    static formatDateTimeDefault(value: any, format: string, isTime: boolean) {
        if (isTime) {
            return this.formatTime(value, format, true);
        }
        return this.format(value, format, true);
    }

    static combineDateTime(date, value) {
        return this.getInstance(date).set(value).format();
    }

    static toUtc(date) {
        return this.getInstance(date).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    static toUtcWithNoTime(date) {
        return this.getInstance(date).utc().set({'hour': 0, 'minute': 0, 'second': 0}).format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
    static toUtcWithEODTime(date) {
        return this.getInstance(date).utc().set({'hour': 23, 'minute': 59, 'second': 0}).format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    static toUtcRecurrenceException(date) {
        return this.getInstance(date).utc().format('YYYYMMDDTHHmmss[Z]');
    }

    static toUtcUntilRecurrenceException(date, hour = 18, minute = 29, seconds = 59, isKeepLocal: boolean = false) {
        return this.getInstance(date).utc(isKeepLocal).set({
            'hour': hour,
            'minute': minute,
            'second': seconds
        }).format('YYYYMMDDTHHmmss[Z]');
    }

    static addAndGetNewValueDefault(date: any, num, type: string) {
        return moment(date).clone().add(type, num).format();
    }

    static combineDateTimeDefault(date, value) {
        return moment(date).set(value).format();
    }

    static _toDate(date, isTimeZoneBased: boolean = false) {
        return new Date(parseInt(this.format(date, 'YYYY', isTimeZoneBased)), (parseInt(this.format(date, 'M', isTimeZoneBased)) - 1), parseInt(this.format(date, 'D', isTimeZoneBased)), parseInt(this.format(date, 'H', isTimeZoneBased)), parseInt(this.format(date, 'm', isTimeZoneBased)), parseInt(this.format(date, 's', isTimeZoneBased)));
    }

    static getTimeObject(date) {
        return {h: date.hours(), m: date.minutes(), s: date.seconds()};
    }

    static getDay(date) {
        return date.format('DD');
    }

    static getMonth(date) {
        return parseInt(date.format('MM')) - 1;
    }

    static getYear(date) {
        return date.format('YYYY');
    }

    static getDateWithTz(date) {
        let offset = this.getInstance(moment()).format('Z');
        let formatedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');

        return formatedDate + '' + offset;
    }

    static getDiffInMin(start, end) {
        let s = moment(start);
        let e = moment(end);

        return e.diff(s, 'minutes');
    }

    static getDiff(start, end, _in: any = 'days'): number {
        let s = moment(start);
        let e = moment(end);

        return e.diff(s, _in);
    }

    static diff(start, end, _in: any = 'ms') {
        let s = this.getInstance(start);
        let e = this.getInstance(end);
        return e.diff(s, _in);
    }

    static humanizeDuration(duration: number, keysArray: string[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds']) {
        const durationObj = moment.duration(duration, 'ms');
        const durationStringArray = [];
        const durationStringArray2 = [];
        const titleCase = (word) => {
            if (!word) return word;
            return word[0].toUpperCase() + word.substring(1).toLowerCase();
        };
        Object.keys(durationObj['_data'])
            .filter((key) => keysArray.indexOf(key) > -1)
            .forEach((key) => {
                if (keysArray.indexOf(key) < 3) {
                    durationStringArray.push(durationObj['_data'][key] <= 0 ? '' : durationObj['_data'][key] + ' ' + titleCase(key));
                } else {
                    durationStringArray2.push(String(durationObj['_data'][key]).padStart(2, '0') + ':');
                }
            });
        return (durationStringArray.reverse().join(' ') + ' ' + durationStringArray2.reverse().join('').slice(0, -1)).trim();
    }
}
